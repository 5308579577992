import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Front Squat 2-2-2-2-2\\@90% 1RM`}</strong></p>
    <p><strong parentName="p">{`DB RDL’s 4-4-4-4-4`}</strong></p>
    <p><strong parentName="p">{`then, `}</strong></p>
    <p><strong parentName="p">{`3 Rounds for time of: `}</strong></p>
    <p><strong parentName="p">{`5-Squat Cleans (145/100)`}</strong></p>
    <p><strong parentName="p">{`10-Wall Balls (20/14)`}</strong></p>
    <p><strong parentName="p">{`5-Power Cleans (145/100)`}</strong></p>
    <p><strong parentName="p">{`10-T2B`}</strong></p>
    <p>{`*`}{`CrossFit Kids at East today from 9:30-10:00am`}</p>
    <p><em parentName="p">{`*`}{`Today (Saturday) we will have our 3rd Annual Sports Nutrition seminar
at `}<strong parentName="em">{`Louisville East`}</strong>{` with Dawn from Sports Nutrition 2 Go in
Cincinnati. The seminar will be from 12-2pm. \\$35/person for the
seminar.`}</em></p>
    <p><em parentName="p">{`Subjects covered will include:`}</em></p>
    <p><em parentName="p">{`Hydration—How impacts performance, electrolytes, what to drink`}</em></p>
    <p><em parentName="p">{`Breakfast—effects afternoon performance by 5% and effects appetite`}</em></p>
    <p><em parentName="p">{`Nutrient Timing—what to eat before/after, when`}</em></p>
    <p><em parentName="p">{`Sleep and Nutrition on performance---Discuss percentages on outcomes`}</em></p>
    <p><em parentName="p">{`Discuss different nutrition philosophies`}</em></p>
    <p><em parentName="p">{`Supplements and Labs: what to take and which labs to draw`}</em></p>
    <p><em parentName="p">{`*`}{`*`}{`Metabolic Testing will be offered before and after the seminar as
well! \\$65 for Metabolic Testing. Please email Daniel if you’d like to
sign up. `}</em></p>
    <p><em parentName="p">{`9:00am: Jeff`}</em></p>
    <p><em parentName="p">{`9:20am: Molly`}</em></p>
    <p><em parentName="p">{`9:40am: Kelsey`}</em></p>
    <p><em parentName="p">{`10:00am: Tammy`}</em></p>
    <p><em parentName="p">{`10:20am: Josh `}</em></p>
    <p><em parentName="p">{`10:40am: Sarah`}</em></p>
    <p>{`*11:00am: `}<strong parentName="p">{`open*`}</strong></p>
    <p><em parentName="p">{`11:20am: Eric`}</em></p>
    <p><em parentName="p">{`11:40am: Chelsey`}</em></p>
    <p><em parentName="p">{`2:00pm: Tara`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      